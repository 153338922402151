import { UnleashClient } from "@unleash/proxy-client-react";
import { getConfig } from "@telia/b2b-unleash-client-config";
import { useEffect, useState } from "react";

interface ReactUnleashClient {
  client: UnleashClient | undefined;
}

interface ClientWithScopeIdAndReady {
  client: UnleashClient | undefined;
  flagsReady: boolean;
  flagsError: boolean;
}

export function useUnleashClientWithScopeId(appName: string): ReactUnleashClient {
  const [client, setClient] = useState<UnleashClient | undefined>();

  useEffect(() => {
    const loadUnleashClient = async () => {
      const config = await getConfig(appName);
      const unleashClient: UnleashClient = new UnleashClient(config);
      setClient(unleashClient);
      await unleashClient.start();
    };
    loadUnleashClient();
  }, []);

  return { client };
}

export function useUnleashClientWithScopeIdAndFlagStatus(
  appName?: string
): ClientWithScopeIdAndReady {
  const [client, setClient] = useState<UnleashClient | undefined>();
  const [flagsReady, setFlagsReady] = useState<boolean>(false);
  const [flagsError, setFlagsError] = useState<boolean>(false);

  useEffect(() => {
    const loadUnleashClient = async () => {
      const config = await getConfig(appName);
      const unleashClient: UnleashClient = new UnleashClient(config);
      unleashClient.on("error", () => {
        setFlagsError(true);
      });
      unleashClient.on("ready", () => {
        setFlagsReady(true);
      });
      setClient(unleashClient);
      await unleashClient.start();
    };
    loadUnleashClient();
  }, []);

  return { client, flagsReady, flagsError };
}
